import { imageAdapter } from '@/adapters/constructor/utils/image';
const formatAdapter = (data) => {
  return {
    text: data.text,
    title: data.title,
    bannerMobile: imageAdapter(null, data.banner_mobile),
  };
};

export default ({ data }) => {
  return {
    title: data.title,
    image: imageAdapter(data.banner),
    formats: data.formats?.map(formatAdapter),
  };
};
